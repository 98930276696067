import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import './getStandards.css'
import axios from '../axios.js'
import * as XLSX from 'xlsx';

function GetStandards() {
const [getData ,setGetData] = useState([])
const [isDisable ,setIsDisable] = useState(true)
    const {register,handleSubmit, setError,formState: { errors,isSubmitting } }= useForm()

    async function useingSubmit(data) {
        try {
            
            const mydata = await axios.post('/getStandardType', data);


            if(mydata.data.data===undefined){
              
              setError("root",{
                message: `${mydata.data.message}`
              })
            }else{
              setGetData(()=>mydata.data.data)
              setIsDisable(false)
            }
        } catch (error) {
            console.log("error in post request:", error);
        }
    }

   

  function handleClick() {
    // Create a new workbook
    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.json_to_sheet(getData);
  
    // Add the worksheet to the workbook
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
  
    // Write the workbook to a file
    XLSX.writeFile(wb, 'data.xlsx', { type: 'buffer' }, function (err) {
      if (err) {
        console.error('Error writing Excel file:', err);
      } else {
        console.log('Excel file written successfully.');
      }
    });
  }
  

  return (
    <div className='h-screen grid sm:grid-cols-2'>
        <div className='get-main'>
        <form
        className="w-full flex h-full items-center justify-center "
        onSubmit={handleSubmit(useingSubmit)}
        >
        
          <div className='type-3Form flex flex-col items-center   bg-slate-500 h-3/5 w-'>
          <h2 className='text-2xl mt-3 font-bold'>GET Standard</h2>
      

          
      <input
        {...register("volume",{
          required:"Volue is required"
        })}
        className="login-input mt-7 w-4/6 border-2   border-blue-600   rounded-sm  mb-2"
        type="text"
        id="BND"
        placeholder='Volue'
        />
{errors.volume && <div className='text-red-500 font-semibold'>{errors.volume.message}</div>}
      
      <input
        {...register("standarCode",{
          required:"Refrence Material/BND Number is required"
        })}
        className="login-input mt-7 w-4/6 border-2   border-blue-600   rounded-sm  mb-2"
        type="number"
        id="certificateNumber"
        placeholder='Refrence Material/BND Number'
        />
       {errors.standarCode && <div className='text-red-500 font-semibold'>{errors.standarCode.message}</div>}
      
      <input
        {...register("lotNumber",{
          required:"Lot Number is required"
        })}
        className="login-input mt-7 w-4/6 border-2   border-blue-600   rounded-sm  mb-2"
        type="text"
        placeholder='Lot Number'
        id="lotNumber"
        />
      {errors.lotNumber && <div className='text-red-500 font-semibold'>{errors.lotNumber.message}</div>}

                
        <button
        disabled={isSubmitting}
        className= {`${isSubmitting===true?'login-submitting':''} border-4 mt-7 border-blue-400 p-2 pl-5 pr-5 rounded login-button  font-bold`}  
        type="submit"
        >
          Submit
        </button>
            {errors.root && <div className='text-red-500 font-semibold'>{errors.root.message}</div>}
         
          </div>
        
          
        
      </form>
        </div>
        <div className=' gap-y-6 getStandards-second justify-center '>
        <button onClick={handleClick} disabled={isDisable} className={`${isDisable===true?'login-submitting':'login-button'} h-16 border-4 mt-7 border-blue-400 p-2 pl-5 pr-5 rounded font-bold`} >{isDisable=== true?'Disabled':'Download'}</button>
       
                {isDisable === true? <h3 className='text-red-600 font-bold'>Enter details to enable download button</h3>: <h3 className='text-green-600 font-bold'>Click button to download data</h3>}
        </div>
      
    </div>
  )
}

export default GetStandards
