import React, { useEffect, useState } from "react";
import {TypeOne,TypeTwo,TypeThree} from '../AddStandard/Forms.jsx'
import { useSwitchCase } from "../../../hooks/useSwitchCase.js";

const UpdateStandard = () => {
 

  const [selectedOption, setSelectedOption] = useState(1003);
  const [selectedStandardOption, setSelectedStandardOption] = useState(1);
  const [uniqueValue ,setUniqueValue] = useState('')
  // Function to handle dropdown change
  const handleDropdownChange = async (e) => {
    setSelectedOption(parseInt(e.target.value, 10)); // Ensure selectedOption is parsed as integer
    // console.log(selectedOption)
  };
  const handleSelectOption = (e) => {
    const newValue = parseInt(e.target.value, 10); // Ensure selectedStandardOption is parsed as integer
    
    setSelectedStandardOption(newValue)

    newValue === 1 ? setSelectedOption(1003):newValue === 2? setSelectedOption(100300500):setSelectedOption(200150001)
  };

  useEffect(()=>{
    if (selectedOption < 2000) {
      setUniqueValue('BND Number')
    } else{
      setUniqueValue('Refrence Material')
      
    }

  },[selectedOption])
  const myValue = useSwitchCase(selectedOption)
  
 

  return (
    <div className="addStandard-main grid sm:grid-cols-2 ">
      <div className="bg-slate-800 flex p-2 flex-col gap-y-2">
        <h2 className="text-2xl border-b-2 text-center pb-2 text-white font-bold mt-1">
          Update Certificate 
        </h2>
        <select
          onChange={handleSelectOption}
          className="border-2 rounded-sm"
          value={selectedStandardOption}
        >
          <option value={1}>BND Certificate </option>
          <option value={2}>AQCRM Certificate </option>
          <option value={3}>OIL Certificate </option>
        </select>
        {selectedStandardOption === 1 ? (
          <select
            className="border-2 rounded-sm"
            value={selectedOption}
            onChange={handleDropdownChange}
          >
            <option value={1003}>BND1003</option>
            <option value={1004}>BND1004</option>
            <option value={1005}>BND1005</option>
            <option value={1006}>BND1006</option>
            <option value={1007}>BND1007</option>
            <option value={1008}>BND1008</option>
            <option value={1009}>BND1009</option>
            <option value={1010}>BND1010</option>
            <option value={1011}>BND1011</option>
            <option value={1012}>BND1012</option>
            <option value={1013}>BND1013</option>
            <option value={1014}>BND1014</option>
            <option value={1015}>BND1015</option>
            <option value={1016}>BND1016</option>
            <option value={1017}>BND1017</option>
            <option value={1018}>BND1018</option>
            <option value={1019}>BND1019</option>
            <option value={1020}>BND1020</option>
            <option value={1021}>BND1021</option>
            <option value={1022}>BND1022</option>
            <option value={1023}>BND1023</option>
            <option value={1024}>BND1024</option>
            <option value={1025}>BND1025</option>
            <option value={1026}>BND1026</option>
            <option value={1027}>BND1027</option>
            <option value={1028}>BND1028</option>
            <option value={1029}>BND1029</option>
            <option value={1030}>BND1030</option>
            <option value={1031}>BND1031</option>
            <option value={1032}>BND1032</option>
            <option value={1033}>BND1033</option>
            <option value={1034}>BND1034</option>
            <option value={1035}>BND1035</option>
            <option value={1036}>BND1036</option>
            <option value={1037}>BND1037</option>
            <option value={1038}>BND1038</option>
            <option value={1039}>BND1039</option>
            <option value={1041}>BND1041</option>
            <option value={1042}>BND1042</option>
          </select>
        ) : selectedStandardOption === 2 ? (
          <select
            className="border-2 rounded-sm"
            value={selectedOption}
            onChange={handleDropdownChange}
          >
            <option value={100300500}>AQCRM 100300500</option>
            <option value={100200500}>AQCRM 100200500</option>
            <option value={100210500}>AQCRM 100210500</option>
            <option value={100390500}>AQCRM 100390500</option>
            <option value={100370500}>AQCRM 100370500</option>
            <option value={100030100}>AQCRM Iron</option>
            <option value={101000040}>AQCRM Colour</option>
            <option value={101000041}>AQCRM Turbidity</option>
          </select>
        ) : (
          <select
            className="border-2 rounded-sm"
            value={selectedOption}
            onChange={handleDropdownChange}
          >
            <option value={200150001}>Groundnut Oil</option>
            <option value={200150002}>Sesame Oil</option>
            <option value={200150003}>Linseed Oil</option>
            <option value={200150004}>Karanja Oil</option>
            <option value={200150005}>Neem Oil</option>
            <option value={200150006}>Castor Oil</option>
            <option value={200150008}>Mineral Oil</option>
            <option value={200150009}>Argemone Oil</option>
          </select>
        )}
      </div>
      
      {myValue === 1? <TypeOne url={'/updateStandardType1'} Isupdate={true} selectedOption= {selectedOption} uniqueValue={uniqueValue}/> : myValue === 2? <TypeTwo url={'/updateStandardType2'} Isupdate={true} selectedOption= {selectedOption} uniqueValue={uniqueValue}/>: <TypeThree url={'/updateStandardType3'} Isupdate={true} selectedOption= {selectedOption} uniqueValue={uniqueValue}/>}
      
      
    </div>
  );
};



export default UpdateStandard
